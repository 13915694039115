<template>
  <div v-on="$listeners">
    <div v-if="icon_type==='big'" class="math-button-container math-button-container--big">
      <base-icon class="big-icon" size="40px" :src="icon_src"/>
    </div>
    <div v-else-if="icon_type==='medium'" class="math-button-container math-button-container--medium">
      <base-icon class="medium-icon" size="24px" :src="icon_src"/>
    </div>
    <div v-else class="math-button-container math-button-container--small">
      <base-icon class="medium-icon" size="16px" :src="icon_src"/>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/components/common/BaseIcon";

export default {
  props: {
    icon_src: String,
    icon_type: String,
  },
  components: {
    BaseIcon
  },
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss">

.math-button-container {
  border: 1px solid black;
  border-radius: 3px;
  width: 70px;
  display: flex;
  justify-content: center;
  span {
    color: black;
  }

}
.math-button-container--small {
  padding: 12px 0;
}
.math-button-container--medium{
  padding: 8px 0;
}
.math-button-container:hover {
  background: #ffbd8c;
  border: 1px solid #f26824;
  cursor: pointer;
}

.big-icon {

}

.medium-icon {
}
</style>
