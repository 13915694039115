<template>
  <div v-on="$listeners">
    <div class="normal-math-button-container">
      <span>{{character}}</span>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    character: String
  },
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss">

.normal-math-button-container {
  border: 1px solid black;
  border-radius: 3px;
  width: 70px;
  display: flex;
  justify-content: center;
  span {
    color: black;
  }
  padding: 8px 0;
}

.normal-math-button-container:hover {
  background: #ffbd8c;
  border: 1px solid #f26824;
  cursor: pointer;
}
</style>
